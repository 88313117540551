define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop.cash-register-retail/collections/loadable/tables',
  './itemAdd',
  './itemInformation',
  './empty',
], (
  $, _, Backbone, Tables, AddView, InformationView, EmptyView,
) => Backbone.Marionette.CollectionView.extend({

  className: 'table-collection',

  emptyView: EmptyView,

  getChildView() {
    const { MODE_ADD } = this.tableModes;

    // Add mode
    if (this.model.get('tableMode') === MODE_ADD) return AddView;

    // Information mode is fallback
    return InformationView;
  },

  childViewOptions() {
    return {
      overviewModel: this.model,
    };
  },

  initialize({ tableModes }) {
    this.collection = Tables.copy();
    this.tableModes = tableModes;
  },

  // Show only the area's table
  filter(model) {
    const { searchQuery, tableAreaId: wantedAreaId } = this.model.toJSON();
    const { table_area_id: modelAreaId, name: modelName } = model.toJSON();

    // compare tableAreaIds
    if (wantedAreaId !== modelAreaId) return false;

    // Check if the query matches the name
    const query = (searchQuery || '').trim().toLowerCase();
    const name = (modelName || '').trim().toLowerCase();
    if (name.indexOf(query) === -1) return false;

    // Success!
    return true;
  },

  /**
		 * Prevent re-rendering unnecessary
		 */
  renderDebounce: _.debounce(function () {
    this.render();
  }, 250),

  onShow() {
    this.model.on('change:searchQuery', this.renderDebounce, this);
    this.model.on('change:tableAreaId', this.renderDebounce, this);
    Tables.on('all', this.updateCollection, this);
  },

  onDestroy() {
    this.model.off('change:searchQuery', this.renderDebounce, this);
    this.model.off('change:tableAreaId', this.renderDebounce, this);
    Tables.off('all', this.updateCollection, this);
  },

  updateCollection() {
    this.collection.reset(Tables.toJSON());
  },

}));
