define([
  'jquery',
  'underscore',
  'backbone',

  'modules/common/components/locale',

  'modules/shop.cash-register-retail/collections/loadable/tables',
  'modules/shop.cash-register-retail/collections/loadable/tableAreas',
  'modules/shop.cash-register-retail/collections/loadable/productionGroups',

  'modules/shop.cash-register-retail/views/tables/addPopup',

  'modules/shop.cash-register-retail/collections/currentOrderItem',
], (
  $, _, Backbone,
  Locale,
  Tables, TableAreas, ProductionGroups,
  AddPopup,
  CurrentOrderItem,
) => Backbone.Marionette.LayoutView.extend({

  template: () => Locale.translate('on_table'),

  tagName: 'button',

  className: 'btn btn-info btn-block designer-box rounded',

  initialize({ printProductionReceipts }) {
    this.printProductionReceipts = printProductionReceipts;

    ProductionGroups.load();
  },

  events: {
    click: 'viewClicked',
  },

  viewClicked() {
    (new AddPopup()).open({
      printProductionReceipts: this.printProductionReceipts,
    });
  },

  onShow() {
    this.checkState();
    CurrentOrderItem.on('all', this.checkState, this);
  },

  onDestroy() {
    CurrentOrderItem.off('all', this.checkState, this);
  },

  checkState() {
    this.$el.attr('disabled', CurrentOrderItem.length === 0);
  },

}));
