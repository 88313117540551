define([
  'jquery',
  'underscore',
  'backbone',
  'modules/mobile/views/popup',
  'modules/shop.cash-register-retail/templates/tables/informationPopup/popup.hbs',
  'modules/common/components/locale',

  './collection',

  'modules/shop.cash-register-retail/components/cashRegisterApi',
  'modules/common/components/promisify',
  'modules/shop.cash-register-retail/components/toaster',

  'modules/shop.cash-register-retail/components/tableOrderManager',
  'modules/shop.cash-register-retail/components/productionGroupPrint',

  'modules/shop.cash-register-retail/collections/loadable/productionGroups',

  'upx.modules/ShopModule/models/TableOrder',
  'modules/shop.cash-register-retail/collections/loadable/tables',
], (
  $, _, Backbone, PopupView, Template, Locale,
  CollectionView,
  CashRegisterApi, Promisify, Toaster,
  TableOrderManager, ProductionGroupPrint,
  ProductionGroups,
  TableOrderModel, Tables,
) => PopupView.extend({

  template: Template,

  className: 'dialog dialog--table-information',

  events: {
    'click [data-action="close"]': 'closeClicked',

    'click [data-action=print-summary]': 'printSummaryClicked',
    'click [data-action=move]': 'moveClicked',
    'click [data-action=close-table]': 'closeTableClicked',
    'click [data-action=pay]': 'payClicked',
  },

  regions: {
    orderParts: '[data-region=order-parts]',
  },

  childEvents: {
    'row:delete': function (__, data) { this.triggerMethod('row:delete', data); },
    'loader:start': function (__, childDef) {
      const def = this.startLoader();
      childDef.always(() => def.resolve());
    },
  },

  initialize(options) {
    PopupView.prototype.initialize.call(this, options);
    this.model = options.model;
  },

  printSummaryClicked: _.debounce(function () {
    const def = this.startLoader();
    ProductionGroupPrint.printTableSummary(
      this.model,
      this.tableOrderItems.get('table_order_items'),
    ).then(() => {
      def.resolve();
    }).catch((e) => {
      def.reject(e);
    });
  }, 50),

  moveClicked() {
    this.close();
    this.triggerMethod('table:move');
  },

  closeTableClicked() {
    const def = this.startLoader();
    const tableOrderModel = new TableOrderModel({
      id: this.model.get('table_order_id'),
    });

    def.then(() => {
      // Notify user the table has been closed
      Toaster.success(Locale.translate('table_{table}_{table_area}_has_been_successfully_closed',
        {
          table: this.model.get('name'),
          table_area: this.model.get('table_area.name'),
        }));

      this.close();
    }, (e) => {
      Toaster.error(Locale.translate('table_{table}_{table_area}_could_not_be_closed',
        {
          table: this.model.get('name'),
          table_area: this.model.get('table_area.name'),
        }));
    });

    // Finalize the empty order
    tableOrderModel.finalizeEmpty().then(() => {
      // Reload the tables so they are updated in the view
      Tables.load({ reload: true }).then(def.resolve, def.reject);
    }, def.reject);
  },

  payClicked() {
    this.close();
    this.triggerMethod('table:pay');
  },

  async renderOverview(tableModel) {
    const def = this.startLoader();

    try {
      if (!tableModel) {

      }
      // Get table order
      const [tableOrderModel] = await Promise.all([
        TableOrderManager.loadById(this.model.get('table_order_id'), true),
        ProductionGroups.loadPromise({ reload: true }),
      ]);
      this.tableOrderItems = tableOrderModel;

      // Done
      def.resolve();

      // Render
      const region = this.getRegion('orderParts');
      const view = new CollectionView({ tableOrderModel, tableModel: this.model });
      region.show(view);
    } catch (err) {
      console.error(err);
      const message = Promisify.extractTryCatchErrorMessage(err);
      Toaster.error(message);
    }
  },

  open(tableModel) {
    CashRegisterApi.logAction('POPUP_OPEN', {
      type: 'modules/shop.cash-register-retail/views/tables/informationPopup/popup',
    });

    this.renderInFloatingRegion();

    this.openPopup();

    this.renderOverview(tableModel);
  },

  serializeData() {
    return {
      table_name: this.model.get('name'),
      table_area_name: this.model.get('table_area.name'),
      order_empty: this.model.get('table_order.value_wt') == '0.00',
    };
  },

}));
