define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop.cash-register-retail/templates/successScreens/invoice/layout.hbs',

  'modules/shop.cash-register-retail/views/payments/processingCollection',
  'modules/shop.cash-register-retail/components/sendInvoice',
  'modules/shop.cash-register-retail/models/settings/receiptPrinter',

  'modules/admin/behaviors/loader',
  'modules/common/components/historyBreadcrumb',
  'modules/shop.cash-register-retail/components/printing',
  'modules/shop.cash-register-retail/views/popups/messagePopup',
], (
  $, _, Backbone, Template,
  ProcessingCollectionView, SendInvoice, ReceiptPrinterModel,
  Loader, HistoryBreadcrumb, PrintingComponent, MessagePopup,
) => Backbone.Marionette.LayoutView.extend({

  className: 'payment-success',

  template: Template,

  behaviors: {
    Loader: {
      behaviorClass: Loader,
    },
  },

  regions: {
    logItems: '[data-region=log-items]',
  },

  events: {
    'click [data-action="back"]': 'backClicked',
    'click [data-action="email"]': 'emailClicked',
    'click [data-action="print"]': 'receiptClicked',
  },

  initialize({
    model,
    customerModel,
    logCollection,
    backRoute = null,
    paymentMethodCollection,
  }) {
    this.model = model;
    this.customerModel = customerModel;
    this.logCollection = logCollection;
    this.backRoute = backRoute;
    this.paymentMethodCollection = paymentMethodCollection;
  },

  backClicked() {
    if (this.backRoute) {
      HistoryBreadcrumb.goto(this.backRoute);
    } else {
      HistoryBreadcrumb.goBack();
    }
  },

  emailClicked() {
    const def = this.loader.startLoader('loader-email');
    SendInvoice.sendById({
      invoiceId: this.model.get('id'),
      def, // SendInvoice.sendById triggers reject/resolve
    });
  },

  receiptClicked() {
    const def = this.loader.startLoader('loader-print');

    PrintingComponent.printInvoiceReceipt(this.model, this.paymentMethodCollection)
      .then(() => {
        this.backClicked();
        def.resolve();
      }, (response) => {
        if (response && 'error' in response) {
          def.reject();

          const view = new MessagePopup();
          view.open(
            response.error,
          );
        }
      });
  },

  onRender() {
    const view = new ProcessingCollectionView({
      collection: this.logCollection,
    });
    const region = this.getRegion('logItems');
    region.show(view);
  },

  serializeData() {
    const data = this.model.toJSON();
    data.has_receipt_printer = ReceiptPrinterModel.isWantedType();
    return data;
  },
}));
