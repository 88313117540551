define([
    'jquery',
    'backbone',
    'modules/shop.cash-register-retail/templates/success/summary',
    'modules/common/components/locale',
    'modules/shop.cash-register-retail/models/settings/paymentMethods',
    'modules/common/components/currency',
    'modules/common/collections/currencies',
], (
    $, Backbone, ItemView, Locale, PaymentMethods, Currency, CurrenciesCollection,
) => {
    return Backbone.Marionette.LayoutView.extend({
        template: ItemView,

        className:'summary',

        initialize({
                       model,
                       logCollection = new Backbone.Collection()
                   }) {
            this.model = model;
            this.payments = this.getPaymentArray(logCollection);
        },

        getPaymentArray(logCollection) {
            const payments = [];
            logCollection.each((model) => {
                const id = model.get('id') || '';
                if( id === "payment|cash"){
                    if( model.has('cashRefunded')){
                        payments.push({
                            type: 'cash-refunded',
                            name: Locale.translate("refunded_by_cash"),
                            value_wt: model.get('cashRefunded'),
                        });
                    }
                    if( model.has('cashReceived')){
                        payments.push({
                            type: 'cash-received',
                            name: Locale.translate("received_by_cash"),
                            value_wt: model.get('cashReceived'),
                        });
                    }
                    if( model.has('changeReturned')){
                        payments.push({
                            type: 'cash-returned',
                            name: Locale.translate("return_in_cash_to_customer"),
                            value_wt: model.get('changeReturned'),
                            className:  model.get('changeReturned') !== '0.00' ? 'has-change': ''
                        })
                    }

                } else if(id.substr(0,8) === 'payment|') {
                    const type = id.substr(8);
                    payments.push({
                        type: type,
                        name: PaymentMethods.getNameByMethod(type),
                        value_wt: model.get('value'),
                    })
                }
            })
            return payments
        },

        serializeData() {
            const iso3 = this.model.get('currency_iso3');
            const currencyObject = CurrenciesCollection.getCurrencyObjectUsingIso3(iso3);
            const currencyIsoSymbol = currencyObject.symbol;
            return $.extend({
                payments: this.payments,
                value_wt_formatted: currencyIsoSymbol + ' '+
                    Currency.formatAmount(iso3, this.model.get('value_wt'))
            }, this.model.toJSON());
        }

    });
});
