define([
  'jquery',
  'underscore',
  'backbone',
  'modules/mobile/views/popup',
  'modules/shop.cash-register-retail/templates/tables/movePopup.hbs',

  'modules/common/components/locale',
  'modules/common/components/promisify',
  'modules/shop.cash-register-retail/components/cashRegisterApi',
  'modules/shop.cash-register-retail/components/toaster',
  'modules/shop.cash-register-retail/components/productionGroupPrint',

  'upx.modules/ShopModule/models/TableOrder',
  'modules/shop.cash-register-retail/components/tableOrderManager',
  'modules/shop.cash-register-retail/collections/loadable/tables',
], (
  $, _, Backbone, PopupView, Template,
  Locale, Promisify, CashRegisterApi, Toaster, ProductionGroupPrint,
  TableOrderModel, TableOrderManager, Tables,
) => PopupView.extend({

  template: Template,

  className: 'dialog dialog--table-move',

  events: {
    'click [data-action="close"]': 'closeClicked',
    'click [data-action=move]': 'moveClicked',
  },

  ui: {
    to: '[data-ui=to]',
  },

  regions: {
    orderParts: '[data-region=order-parts]',
  },

  initialize(options) {
    PopupView.prototype.initialize.call(this, options);
    this.model = options.model;
    this.availableTables = this.getAvailableTables();
  },

  async moveClicked() {
    const def = this.startLoader();

    try {
      const table_id = this.ui.to.val();
      const targetTable = Tables.get(table_id);
      const tableOrderModel = new TableOrderModel({
        id: this.model.get('table_order_id'),
        table_id,
      });
      await tableOrderModel.savePromise();

      // Update the tables, because it's a popup and the items are below
      await Tables.loadPromise({ reload: true });

      // Notify the user
      Toaster.success(Locale.translate('move_items_to_{name}_{area_name}', {
        name: targetTable.get('name'),
        area_name: targetTable.get('table_area.name'),
      }));

      // Done
      def.resolve();
      this.close();
    } catch (err) {
      console.error(err);
      const message = Promisify.extractTryCatchErrorMessage(err);
      Toaster.error(message);
    }
  },

  async fetchData() {
    const def = this.startLoader();

    try {
      // Get table order
      await Tables.load({ reload: true });

      // Done
      def.resolve();

      // Render
      this.render();
    } catch (err) {
      console.error(err);
      const message = Promisify.extractTryCatchErrorMessage(err);
      Toaster.error(message);
    }
  },

  open() {
    CashRegisterApi.logAction('POPUP_OPEN', {
      type: 'modules/shop.cash-register-retail/views/tables/informationPopup/popup',
    });

    this.renderInFloatingRegion();

    this.openPopup();

    this.fetchData();
  },

  getAvailableTables() {
    return Tables.chain()
      .filter((model) => !model.has('table_order'))
      .map((model) => model.toJSON())
      .value();
  },

  serializeData() {
    return {
      table_name: this.model.get('name'),
      table_area_name: this.model.get('table_area.name'),
      availableTables: this.availableTables,
      hasTablesAvailable: this.availableTables.length > 0,
    };
  },

}));
