define([
  'modules/shop.cash-register-retail/views/productListWithSearch/list/layout',
  '../../products/list/layout',
], (View, ProductListView) => View.extend({
  getColumns() {
    const columns = View.prototype.getColumns.call(this);

    columns.push('reserve');

    return columns;
  },

  getProductListView() {
    return new ProductListView({
      columns: this.getColumns(),
      collection: this.orderItemCollection,
    });
  },
}));
