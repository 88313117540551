define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop.cash-register-retail/templates/tables/overview/tableArea/item.hbs',
], (
  $, _, Backbone, Template,
) => Backbone.Marionette.LayoutView.extend({

  className: 'btn btn-block designer-box rounded table-area-item',

  tagName: 'button',

  template: Template,

  events: {
    click: 'viewClicked',
  },

  viewClicked() {
    this.overviewModel.trigger('clear:searchQuery');
    this.overviewModel.set('tableAreaId', this.model.get('id'));
  },

  initialize(options) {
    this.overviewModel = options.overviewModel;
  },

  onShow() {
    this.toggleState();
    this.overviewModel.on('change:tableAreaId', this.toggleState, this);
  },

  onDestroy() {
    this.overviewModel.off('change:tableAreaId', this.toggleState, this);
  },

  toggleState() {
    if (this.overviewModel.get('tableAreaId') === this.model.get('id')) {
      this.$el.addClass('btn-info');
    } else {
      this.$el.removeClass('btn-info');
    }
  },

}));
