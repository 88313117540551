define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop.cash-register-retail/templates/tabs/listPopup/collection.hbs',

  './item',
  './empty',

  'modules/shop.cash-register-retail/collections/tab',

  'modules/common/components/moment',
], (
  $, _, Backbone, Template,
  ItemView, EmptyView,
  TabCollection,
  Moment,
) => Backbone.Marionette.CompositeView.extend({

  template: Template,

  className: 'tabs-layout',

  childViewContainer: '[data-region="tabs"]',

  childView: ItemView,

  emptyView: EmptyView,

  collection: TabCollection,

  viewComparator(modelA, modelB) {
    const timeA = modelA.get('date_updated');
    const timeB = modelB.get('date_updated');

    if (timeB > timeA) {
      return 1;
    } if (timeA > timeB) {
      return -1;
    }
    return 0;
  },

  filter(model) {
    if ('searchQuery' in this.options && _.isString(this.options.searchQuery)) {
      let { searchQuery } = this.options;
      let title = model.get('name') || '';

      searchQuery = searchQuery.toLowerCase();
      title = title.toLowerCase();

      return title.indexOf(searchQuery) !== -1;
    }
    return true;
  },

  childEvents: {
    'tab:info': 'tabInfo',
    'tab:merge-order-items': 'tabMergeOrderItems',
  },

  tabInfo(__, model) {
    this.triggerMethod('tab:info', model);
  },

  tabMergeOrderItems(__, model) {
    this.triggerMethod('tab:merge-order-items', model);
  },

}));
