define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop.cash-register-retail/templates/repairs/overview/layout.hbs',

  './list',

  'upx.modules/ShopModule/collections/Repair',
  'upx.modules/ShopModule/models/Repair',

  'modules/common/components/locale',
  'modules/admin/behaviors/loader',
  'modules/common/components/historyBreadcrumb',
  'toastr',
], (
  $, _, Backbone, Template,
  ListView,
  RepairCollection, RepairModel,
  Locale, Loader, HistoryBreadcrumb, Toastr,
) => Backbone.Marionette.LayoutView.extend({

  template: Template,

  className: 'repairs',

  regions: {
    items: '[data-region="items"]',
  },

  events: {
    'change [data-action="search"]': 'searchChanged',
    'keyup [data-action="search"]': 'searchChanged',

    'click [data-action="more"]': 'moreClicked',
    'touchend [data-action="more"]': 'moreClicked',

    'click [data-action="create"]': 'createClicked',
    'touchend [data-action="create"]': 'createClicked',

    'click [data-action="clear"]': 'clearClicked',
    'touchend [data-action="clear"]': 'clearClicked',
  },

  ui: {
    search: '[data-ui="search"]',
    more: '[data-ui="more"]',
  },

  behaviors: {
    Loader: {
      behaviorClass: Loader,
    },
  },

  childEvents: {
    'shipment:success': 'searchChanged',
  },

  initialize() {
    this.collection = new RepairCollection();
  },

  createClicked: _.debounce(() => {
    HistoryBreadcrumb.goto('repairs/create');
  }, 30),

  searchChanged: _.debounce(function () {
    this.updateCollection();
  }, 500),

  moreClicked: _.debounce(function () {
    if (this.collection.canFetchNext()) {
      const def = this.loader.startLoader('more');
      const self = this;
      this.collection.fetchNext()
        .always(() => {
          self.checkMore();
          def.resolve();
        });
    }
  }, 30),

  clearClicked() {
    this.ui.search.val('');
    this.ui.search.trigger('keyup');
    this.ui.search.trigger('change');
    // Set the focus to the search view and popup the keyboard by `clicking` on it
    this.ui.search.focus();
    this.ui.search.click();
  },

  checkMore() {
    if (!this.isDestroyed) {
      if (this.collection.canFetchNext()) {
        this.ui.more.show();
      } else {
        this.ui.more.hide();
      }
    }
  },

  updateCollection() {
    const def = this.loader.startLoader('main');
    const filters = [];

    const value = this.ui.search.val();
    const query = value.trim();
    if (query.length > 0) {
      filters.push({
        name: 'search__word',
        val: query,
      });
    }

    const params = {
      params: {
        start: 0,
        limit: 20,
        sort: [{
          name: 'date_created',
          dir: 'desc',
        }],
        filters,
      },
    };

    const self = this;
    this.collection.fetch(params)
      .always(() => {
        self.checkMore();
        def.resolve();
      });
  },

  onShow() {
    this.updateCollection();
    // Set the focus to the search view and popup the keyboard by `clicking` on it
    this.ui.search.focus();
    this.ui.search.click();
  },

  onRender() {
    this.renderItems();
  },

  renderItems() {
    const region = this.getRegion('items');
    const view = new ListView({
      collection: this.collection,
      loader: this.loader,
    });
    region.show(view);
  },

}));
