define([
  'jquery',
  'underscore',
  'backbone',
  'modules/mobile/views/popup',
  'modules/shop.cash-register-retail/templates/shippingModal/deliverPopup.hbs',

  'modules/shop.cash-register-retail/collections/upx/ShipmentStatusType.js',
  'upx.modules/ShippingModule/models/Shipment',
  'modules/shop.cash-register-retail/components/shippingMethod.js',

  'modules/shop.cash-register-retail/components/toaster',
  'modules/common/components/locale',
  'modules/shop.cash-register-retail/components/printing',
  'modules/shop.cash-register-retail/models/settings/receiptPrinter',
], (
  $, _, Backbone, PopupView, Template,
  ShipmentStatusTypeCollection, ShipmentModel, ShippingMethod,
  Toaster, Locale, PrintingComponent, ReceiptPrinter,
) => PopupView.extend({

  template: Template,

  className: 'dialog dialog--order-shipping',

  ui: {
    closeButton: '[data-ui=close]',
    confirmButton: '[data-ui="confirm"]',
    confirmPrintButton: '[data-ui="confirm-print"]',
  },

  events: {
    'click @ui.closeButton': 'closeClicked',
    'click @ui.confirmButton': 'saveClicked',
    'click @ui.confirmPrintButton': 'savePrintClicked',
  },

  initialize({ shipmentId }) {
    this.shipmentId = shipmentId;
  },

  open() {
    this.logOpenAction(
      'modules/shop.cash-register-retail/views/shippingModal/deliverPopup',
    );

    this.renderInFloatingRegion();

    this.openPopup();
  },

  saveClicked() {
    return this.deliverShipment(false);
  },

  async deliverShipment(printConfirm) {
    const def = this.startLoader();

    try {
      const deliveredStatusTypeId = ShipmentStatusTypeCollection.getDeliveredId();
      await this.updateShipment(this.shipmentId, deliveredStatusTypeId);
      Toaster.success(Locale.translate('marked_selected_items_as_collected'));

      if (printConfirm) {
        this.printConfirmation();
      }
      this.model.trigger('list:reload');

      this.confirm();
    } catch (err) {
      Toaster.error(err.error);
    } finally {
      def.resolve();
    }
  },

  savePrintClicked() {
    return this.deliverShipment(true);
  },

  printConfirmation() {
    PrintingComponent
      .printOrderShipmentDelivery(this.model, this.shipmentId)
      .fail((err) => {
        Toaster.error(err.error);
      });
  },

  updateShipment(shipmentId, deliveredStatusTypeId) {
    return new Promise((resolve, reject) => {
      const model = new ShipmentModel({
        notify: false,
        shipment_status_type_id: deliveredStatusTypeId,
        id: shipmentId,
      });

      model.save().then(resolve, reject);
    });
  },

  serializeData() {
    const shipmentCollection = this.model.getShipmentItemCollection();
    const shipmentModel = shipmentCollection.get(this.shipmentId);
    const icon = ShippingMethod.getIconForShipment(shipmentModel);
    const shipment = {
      id: shipmentModel.get('id'),
      icon,
      name: shipmentModel.get('shipping_method.name'),
      status_name: ShippingMethod.getStatusName(shipmentModel.get('shipment_status_type.alias')),
      status_label: ShippingMethod.getStatusLabelClass(shipmentModel.get('shipment_status_type.alias')),
      shipment_items: shipmentModel.get('shipment_items'),
    };
    return {
      ...shipment,
      number: this.model.get('number'),
      hasReceiptPrinter: ReceiptPrinter.isWantedType(),
    };
  },

}));
