define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop.cash-register-retail/templates/tabs/infoPopup/timeline.hbs',

  'modules/shop.cash-register-retail/collections/tab',
  'modules/shop.cash-register-retail/collections/orderItem',

  'modules/common/components/moment',
  'modules/common/components/locale',
  'modules/shop.cash-register-retail/components/toaster',
  'modules/shop.cash-register-retail/components/printing',
  'modules/profile/models/profile',
  'modules/common/components/currency',
], (
  $, _, Backbone, Template,
  TabCollection, OrderItemCollection,
  Moment, Locale, Toaster, Printing, ProfileModel,
  Currency,
) => Backbone.Marionette.LayoutView.extend({

  template: Template,

  className: 'tabs-timeline',

  collection: TabCollection,

  ui: {
    printBtn: 'button[data-action="print-timeline"]',
  },

  events: {
    'click @ui.printBtn': 'printTimeline',
  },

  initialize(options) {
    this.timelines = this.getTimeline();
    this.total_wt = this.model.getOrderTotals();
    this.printingDef = new $.Deferred();
    this.printingDef.resolve();
  },

  startLoader() {
    const def = new $.Deferred();
    this.triggerMethod('loader:def', def);
    return def;
  },

  printTimeline: _.debounce(function (ev) {
    const el = ev.currentTarget;
    const time = el.dataset.timeline;
    this.printOnPrinter(time);
  }, 50),

  printAllTimelines: _.debounce(function () {
    this.printOnPrinter();
  }, 50),

  printOnPrinter(timeline) {
    if (this.printingDef.state() === 'pending') {
      console.warn('Printing is in progress');
      return; //
    }
    const def = this.startLoader();

    const data = {
      total_wt: this.total_wt,
      currency_iso3: this.model.getCurrencyIso3(),
      name: this.model.get('name'),
      print_person: ProfileModel.getFullProfileName().trim(),
      print_time: new Date().getTime(),
      for_kitchen: !!timeline,
    };

    if (data.for_kitchen) {
      Toaster.info(Locale.translate('printing_kitchen_receipt'));
    } else {
      Toaster.info(Locale.translate('printing_the_full_tab'));
    }
    // set items
    if (timeline) {
      const timelineData = this.timelines[timeline];
      data.timeline = timelineData.time * 1000; // to ms
      data.timeline_author_name = timelineData.author_name;
      data.order_items = timelineData.order_items;
    } else {
      data.order_items = this.model.get('order_items') || [];
    }

    if (timeline) {
      this.printingDef = Printing.printKitchenTableOrder(data);
    } else {
      this.printingDef = Printing.printTabOrder(data);
    }
    this.printingDef.always(() => def.resolve()); // resolve the loader
  },

  roundUpTo(date, everyXMinutes) {
    const start = Moment(date);
    const remainder = everyXMinutes - (start.minute() % everyXMinutes);
    return Moment(start).add(remainder, 'minutes').startOf('minute');
  },

  getTimeline() {
    const timeline = {};
    const orderItemCollection = new OrderItemCollection(this.model.get('order_items'));
    // reverse the collection based on time
    orderItemCollection.comparator = function (model) {
      return -model.get('time');
    };
    orderItemCollection.sort();

    orderItemCollection.each((orderItemModel) => {
      const roundedTime = this.roundUpTo(orderItemModel.get('time'), 5);
      const time = roundedTime.unix(); // timestamp in secconds
      if (!(time in timeline)) {
        timeline[time] = {
          time,
          title: roundedTime.format('HH:mm'),
          date: roundedTime.format('L'),
          author_name: orderItemModel.get('author_name'),
          order_items: [],
        };
      }

      timeline[time].order_items.push(orderItemModel.toJSON());
    });

    return timeline;
  },

  serializeData() {
    return {
      timeline: this.timelines,
    };
  },

}));
