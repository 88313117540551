define([
  'jquery',
  'underscore',
  'backbone',
  './item',
  'modules/shop.cash-register-retail/templates/tables/overview/table/itemInformation.hbs',

  'modules/shop.cash-register-retail/views/tables/informationPopup/popup',
  'modules/shop.cash-register-retail/views/tables/movePopup',
  'modules/shop.cash-register-retail/views/popups/confirmPopup',

  'modules/common/components/promisify',
  'modules/common/components/currency',
  'modules/shop.cash-register-retail/components/toaster',
  'modules/shop.cash-register-retail/components/productionGroupPrint',
  'modules/shop.cash-register-retail/components/tableOrderManager',
  'modules/admin/behaviors/loader',

  'modules/shop.cash-register-retail/components/orderMemory',
  'modules/common/components/locale',

  'modules/shop.cash-register-retail/collections/currentOrderItem',

  'upx.modules/ShopModule/models/TableOrderItem',
], (
  $, _, Backbone, ItemView, Template,
  InformationPopup, MovePopup, ConfirmPopup,
  Promisify, Currency, Toaster, ProductionGroupPrint, TableOrderManager, Loader,
  OrderMemory, Locale,
  CurrentOrderItems,
  TableOrderItemModel,
) => ItemView.extend({

  template: Template,

  behaviors: {
    Loader: {
      behaviorClass: Loader,
      name: 'information-item',
    },
  },

  events: {
    'click [data-action=information]': 'informationClicked',
    'click [data-action=print]': 'printClicked',
    'click [data-action=pay]': 'payClicked',
    'click [data-checkout]': 'checkoutClicked',
  },

  modelEvents: {
    'change:table_order.value_wt': 'render',
  },

  serializeData() {
    const data = this.model.toJSON();

    // If the order does not have any value, it is empty
    data.order_empty = this.model.get('table_order.value_wt') == '0.00';

    return data;
  },

  informationClicked() {
    const { model } = this;
    const popup = new InformationPopup({ model });

    popup.on('table:pay', () => this.payClicked());
    popup.on('table:move', () => {
      const movePopup = new MovePopup({ model });

      movePopup.open();
    });
    popup.on('row:delete', (model) => {
      const { quantity, name, id } = model.toJSON();
      popup.close();

      const confirmTitle = Locale.translate('are_you_sure');
      const confirmDescription = Locale.translate('do_you_want_to_remove_{quantity}_x_{name}_from_the_{table_name}_{table_area}', {
        quantity,
        name,
        table_name: this.model.get('name'),
        table_area: this.model.get('table_area.name'),
      });

      const def = this.loader.startLoader();
      def.then(() => this.informationClicked(), (resp) => {
        Toaster.error(resp.error);
        // reopen the info popup;
        this.informationClicked();
      });

      const confirmPopup = new ConfirmPopup();
      confirmPopup.open(confirmTitle, confirmDescription)
        .then(() => {
          // remove item
          const tableOrderItemModel = new TableOrderItemModel({ id });
          tableOrderItemModel.destroy()
            .then(() => {
              // Update the table data
              this.model.fetch()
                .then(def.resolve, def.reject);
            }, def.reject);
        }, def.resolve);
    });

    popup.open();
  },

  printClicked() {
    const not_printed_to_production_parts = this.model.get('table_order.not_printed_to_production_parts');
    if (not_printed_to_production_parts === 1) {
      this.printRemainderPart();
    } else {
      this.informationClicked();
    }
  },

  async printRemainderPart() {
    const def = this.loader.startLoader();

    try {
      // Get full table order data
      const tableOrderId = this.model.get('table_order_id');
      const tableOrderModel = await TableOrderManager.loadById(
        tableOrderId,
        true, // Ensure we have the newest data is loaded
      );

      // Get order part
      const tableOrderParts = tableOrderModel.get('table_order_parts');
      const sortedTableOrderParts = tableOrderParts.sort((a, b) => new Date(b.date) - new Date(a.date));
      const tableOrderPart = _.findWhere(sortedTableOrderParts, {
        needs_print_to_production: true,
        times_printed_to_production: 0,
      });

      // Get order items for order part
      const tableOrderItem = tableOrderModel.get('table_order_items');
      tableOrderPart.table_order_items = _.filter(tableOrderItem, (item) => tableOrderPart.id === item.table_order_part_id);
      // Increment print count
      await ProductionGroupPrint.incrementTableOrderPartPrintCount(tableOrderPart.id);
      // Print
      await ProductionGroupPrint.printFromTableOrderPartProduction(this.model.get('id'), tableOrderPart);

      // Update the table.
      await this.model.fetchPromise();

      // Done
      def.resolve();

      // Update the view
      if (!this.isDestroyed) this.render();
    } catch (err) {
			    console.error(err);
			    def.reject(err);
      const message = Promisify.extractTryCatchErrorMessage(err);
      Toaster.error(message);
    }
  },

  async payClicked() {
    await this.pushTableOrderToOrder();
    Backbone.history.navigate(`payment/table/${this.model.get('id')}`, { trigger: true });
  },

  async pushTableOrderToOrder() {
    const def = this.loader.startLoader();

    // Check if the current table order has any items
    const tableOrderModel = await TableOrderManager.loadById(
      this.model.get('table_order_id'),
      true, // Ensure we have the newest data is loaded
    );
    const tableOrderItems = tableOrderModel.get('table_order_items') || [];

    // Check if there are items in the current order
    const confirmDef = new $.Deferred();
    if (CurrentOrderItems.length > 0) {
      (new ConfirmPopup()).open(
        Locale.translate('this_action_will_clear_items_in_order_dot'),
        Locale.translate('there_are_items_in_your_current_order_these_will_be_removed_when_proceeding_dot'),
        confirmDef,
      );
    } else {
      confirmDef.resolve();
    }

    // Add table order items to the checkout
    confirmDef.then(() => {
      const orderItems = this.correctTableOrderItems(tableOrderItems);
      OrderMemory.replaceOrderItemsInCheckout(orderItems).then(
        def.resolve,
        def.reject,
      );
    });

    await Promisify.deferredToPromise(def);
  },

  correctTableOrderItems(orderItems = []) {
    return orderItems
      .filter((orderItem) => orderItem.quantity > 0)
      .map((orderItem) => {
        // correct the subitems > order_items
        orderItem.sub_items = orderItem.subitems;
        delete orderItem.subitems;

        // restore extra
        const { sub_items = [] } = orderItem;
        const restoreExtra = (item) => item.extra = JSON.parse(item.extra || '{}');
        restoreExtra(orderItem);
        sub_items.forEach((item) => restoreExtra(item));

        // restore the discount data
        const restoreDiscount = (item) => {
          const extra = item.extra || {};
          // Check of the item has discount data
          if ('discount_data' in extra) {
            const data = extra.discount_data;
            _.extend(item, {
              ppu: data.ppu,
              ppu_wt: data.ppu_wt,
              discount_ppu_wt: data.discount_ppu_wt,
              discount_percentage: data.discount_percentage,
            });
          }
        };

        restoreDiscount(orderItem);
        sub_items.forEach((item) => restoreDiscount(item));

        return orderItem;
      });
  },

  checkoutClicked() {
    Backbone.history.navigate(`checkout/table/${this.model.get('id')}`, { trigger: true });
  },

}));
