define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop.cash-register-retail/collections/loadable/tableAreas',
  './item',
], (
  $, _, Backbone, TableAreas, ItemView,
) => Backbone.Marionette.CollectionView.extend({

  className: 'table-area-collection',

  childView: ItemView,

  childViewOptions() {
    return {
      overviewModel: this.model,
    };
  },

  initialize() {
    this.collection = TableAreas.copy();
  },

}));
