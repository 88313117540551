define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop.cash-register-retail/templates/success/layout.hbs',

  'modules/shop.cash-register-retail/collections/currentOrderItem',
  'modules/shop.cash-register-retail/collections/currentPaymentMethodItem',
  'upx.modules/ShopModule/collections/OrderInvoice',

  'modules/shop.cash-register-retail/models/selectedCustomer',
  'modules/profile/models/profile',
  'modules/shop.cash-register-retail/models/settings/receiptPrinter',
  'upx.modules/BillingModule/models/Invoice',
  'modules/shop.cash-register-retail/models/customerScreenData',

  'modules/common/components/locale',
  'modules/upx/components/upx',
  'modules/admin/behaviors/loader',
  'toastr',
  'modules/shop.cash-register-retail/components/sendInvoice',
  'modules/shop.cash-register-retail/components/feature',

  'modules/shop.cash-register-retail/views/popups/messagePopup',
  'modules/shop.cash-register-retail/views/success/summary',
  'modules/shop.cash-register-retail/components/printing',
  'modules/shop.cash-register-retail/collections/lastOrders',
  'modules/shop.common/components/deviceConfig',

  'modules/shop.cash-register-retail/models/settings/paymentMethods',
], (
  $, _, Backbone, Template,
  OrderItemCollection, PaymentMethodItemCollection, OrderInvoiceCollection,
  SelectedCustomerModel, ProfileModel, ReceiptPrinterModel, InvoiceModel, CustomerScreenDataModel,
  Locale, Upx, Loader, Toastr, SendInvoice, Feature,
  MessagePopup, SummaryView, PrintingComponent, LastOrdersCollection, DeviceConfig,
  PaymentMethods,
) => Backbone.Marionette.LayoutView.extend({

  template: Template,

  className: 'payment-success order-success-cashier',

  events: {
    'click [data-action="receipt"]': 'receiptClicked',
    'click [data-action="kitchen"]': 'kitchenClicked',
    'click [data-action="no-receipt"]': 'noReceiptClicked',
    'click [data-action="email"]': 'emailClicked',
  },

  regions: {
    payments: '[data-region="payments"]',
  },

  behaviors: {
    Loader: {
      behaviorClass: Loader,
    },
  },

  initialize() {
    this.order = LastOrdersCollection.getLastSuccessOrder();
    this.logCollection = LastOrdersCollection.getLastSuccessOrderLogCollection();

    const lastPayments = LastOrdersCollection.getLastSuccessPaymentsCollection();

    // Check if the last payments had any CCV pin payment in it.
    this.hasCCVPayment = lastPayments.some((payment) => {
      const paymentMethod = payment.get('paymentMethod');

      if (paymentMethod.id === PaymentMethods.PIN_METHOD) {
        return paymentMethod.isCCVPayment;
      }

      return false;
    });
  },

  noReceiptClicked() {
    Backbone.history.navigate('checkout', { trigger: true });
  },

  receiptClicked() {
    const def = this.loader.startLoader('loader-print');
    const lastOrder = LastOrdersCollection.getLastSuccessOrder();

    const options = {
      // Because ccv will always print a receipt, this one is a reprint when there is a ccv payment
      is_reprint: this.hasCCVPayment,
    };

    PrintingComponent.printReceipt(lastOrder, options)
      .then(() => {
        Backbone.history.navigate('checkout', { trigger: true });
        def.resolve();
      }, (response) => {
        def.reject();

        if (response && 'error' in response) {
          const view = new MessagePopup();
          view.open(
            response.error,
          );
        }
      });
  },

  kitchenClicked() {
    const def = this.loader.startLoader('loader-kitchen');
    def.then(() => {
      Backbone.history.navigate('checkout', { trigger: true });
    }, (resp) => {
      if (resp && 'error' in resp) {
        def.reject();

        const view = new MessagePopup();
        view.open(
          resp.error,
        );
      }
    });

    const lastOrder = LastOrdersCollection.getLastSuccessOrder();
    const number = lastOrder.get('number').substr(-2);

    PrintingComponent.printKitchenReceipt(lastOrder, { number })
      .then(() => {
        if (!this.hasCCVPayment) {
          PrintingComponent.printReceipt(lastOrder, { number })
            .then(def.resolve, def.reject);
        } else {
          def.resolve();
        }
      }, def.reject);
  },

  lastOrderHasTurnover() {
    const orderItems = LastOrdersCollection.getLastSuccessOrder().get('order_items');
    let hasTurnover = false;
    if (orderItems && orderItems.length) {
      for (const i in orderItems) {
        const orderItem = orderItems[i];
        if (orderItem.is_turnover) {
          hasTurnover = true;
          break;
        }
      }
    }
    return hasTurnover;
  },

  emailClicked() {
    const def = this.loader.startLoader('loader-email');
    this.fetchInvoiceId()
      .then((invoiceId) => {
        SendInvoice.sendById({
          invoiceId,
          def, // SendInvoice.sendById triggers reject/resolve
          initialEmail: SelectedCustomerModel.get('contact_set.email'),
        }).then(
          () => {
            Backbone.history.navigate('checkout', { trigger: true });
          },
        );
      }, def.reject);
  },

  fetchInvoiceId() {
    const def = new $.Deferred();

    const orderInvoiceCollection = new OrderInvoiceCollection();
    const params = {
      start: 0,
      limit: 1,
      sort: [{
        name: 'invoice_id',
        dir: 'asc',
      }],
      filters: [{
        name: 'order_id__=',
        val: LastOrdersCollection.getLastSuccessOrderId(),
      }],
    };

    orderInvoiceCollection.fetch({ params })
      .then(() => {
        if (orderInvoiceCollection.length > 0) {
          const orderInvoiceModel = orderInvoiceCollection.first();
          def.resolve(orderInvoiceModel.get('invoice_id'));
        } else {
          def.reject({
            error: Locale.translate('could_no_find_order_invoice_dot'),
          });
        }
      }, def.reject);

    return def;
  },

  onShow() {
    const lastOrderId = LastOrdersCollection.getLastSuccessOrderId();
    if (!lastOrderId) {
      Backbone.history.navigate('checkout', { trigger: true });
      return;
    }

    CustomerScreenDataModel.updatePreviousOrderId(lastOrderId);

    this.getRegion('payments').show(new SummaryView({
      logCollection: this.logCollection,
      model: this.order,
    }));
  },

  onDestroy() {
    PaymentMethodItemCollection.clear();
    OrderItemCollection.clear();
    SelectedCustomerModel.unload();
  },

  serializeData() {
    const data = {};

    data.order = this.order.toJSON();
    data.payment_methods = [];
    PaymentMethodItemCollection.each((paymentMethodItemModel) => {
      if (paymentMethodItemModel.get('ppu_wt') !== '0.00') {
        data.payment_methods.push(paymentMethodItemModel.toJSON());
      }
    });
    data.has_paid_payment = true;
    data.has_cups_printer = ReceiptPrinterModel.isWantedType();
    PaymentMethodItemCollection.each((PaymentMethodItemModel) => {
      if (
        PaymentMethodItemModel.get('id') === PaymentMethodItemCollection.PAYLATER_METHOD
                    || PaymentMethodItemModel.get('id') === PaymentMethodItemCollection.INVOICE_METHOD
      ) {
        data.has_paid_payment = false;
      }
    });

    data.showKitchenReceipt = DeviceConfig.MODE_Hospitality === DeviceConfig.getDeviceMode();
    data.has_ccv_payment = this.hasCCVPayment;
    data.is_invoice_payment = !PaymentMethodItemCollection.get(
      PaymentMethodItemCollection.PAYLATER_METHOD,
    ) && this.lastOrderHasTurnover();
    data.isEmailFeatureAvailable = Feature.getFeatureActiveByAppName(
      Feature.APP_NAME_CORE_EMAIL_MODULE_EMAIL,
    );

    return data;
  },

  toDataURL(url, callback) {
    const xhr = new XMLHttpRequest();
    xhr.onload = function () {
      const reader = new FileReader();
      reader.onloadend = function () {
        callback(reader.result);
      };
      reader.readAsDataURL(xhr.response);
    };
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();
  },

}));
