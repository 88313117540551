define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop.cash-register-retail/templates/repairs/overview/listItem.hbs',
  'modules/common/components/historyBreadcrumb',
  'modules/shop.cash-register-retail/components/repair',
], (
  $, _, Backbone, Template, HistoryBreadcrumb, RepairComponent,
) => Backbone.Marionette.LayoutView.extend({

  template: Template,

  tagName: 'tr',

  events: {
    'click [data-action="details"]': 'detailsClicked',
    'touchend [data-action="details"]': 'detailsClicked',
  },
  detailsClicked: _.debounce(function () {
    HistoryBreadcrumb.goto(`repairs/${this.model.get('id')}`);
  }, 100),

  serializeData() {
    const data = this.model.toJSON();
    let description = data.description || '';
    if (description.length > 250) {
      description = `${description.substr(0, 250)}…`;
    }
    return $.extend({
      statusTitle: RepairComponent.getStatusTitle(this.model),
      statusClass: RepairComponent.getStatusClass(this.model),
      descriptionShort: description,
    }, data);
  },
}));
