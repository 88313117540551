define([
  'modules/shop.cash-register-retail/views/products/list/cell',
  'modules/shop.cash-register-retail/templates/repairs/details/products/list/cellReserve.hbs',

  'modules/shop.cash-register-retail/views/popups/confirmPopup',
  'modules/common/components/locale',
], (CellView, Template,
  ConfirmPopupView, Locale) => CellView.extend({
  template: Template,

  events: {
    'click [data-action="set-is-reserved"]': 'setIsReservedClicked',
  },

  setIsReservedClicked() {
    const view = new ConfirmPopupView();
    let message = Locale.translate('are_you_sure_that_you_want_to_lock_and_reserve_the_quantity_for_this_item_question_mark');

    if (this.productModel.get('is_reserved')) {
      message = Locale.translate('are_you_sure_that_you_want_to_unlock_and_return_the_quantity_for_this_item_question_mark');
    }
    view.open(
      Locale.translate('are_you_sure_question'),
      message,
    ).then(() => {
      this.productModel.set('is_reserved', !this.productModel.get('is_reserved'));
      this.productModel.save();
      this.render();
    });
  },

  initialize({ productModel }) {
    this.productModel = productModel;
  },
}));
