define([
  'jquery',
  'underscore',
  'backbone',
  'application',
  'modules/shop.cash-register-retail/templates/stickerPrint/list.hbs',
  './listItem',
], (
  $, _, Backbone, App, Template, ItemView,
) => Backbone.Marionette.CompositeView.extend({

  template: Template,

  childView: ItemView,

  childViewContainer: '[data-region="categories"]',

}));
