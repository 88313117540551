define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop.cash-register-retail/templates/tables/overview/search.hbs',
], (
  $, _, Backbone, Template,
) => Backbone.Marionette.LayoutView.extend({

  className: 'table-overview-search full-child-height',

  template: Template,

  ui: {
    search: '[data-ui=search]',
    clear: '[data-ui=clear]',
  },

  events: {
    'change @ui.search': 'searchChanged',
    'keyup @ui.search': 'searchChanged',
    'click @ui.clear': 'clearClicked',
  },

  modelEvents: {
    'clear:searchQuery': 'clearClicked',
  },

  searchChanged() {
    const value = this.ui.search.val() || '';
    this.model.set('searchQuery', value);
  },

  clearClicked() {
    this.ui.search.val('');
    this.model.set('searchQuery', '');
  },

}));
