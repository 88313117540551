define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop.cash-register-retail/templates/shippingModal/overview/collectionItem',

  'modules/shop.cash-register-retail/views/inputs/integerInput',
], (
  $, _, Backbone, Template,
  IntegerInput,
) => Backbone.Marionette.LayoutView.extend({

  template: Template,

  tagName: 'tr',

  regions: {
    input: '[data-region=input]',
  },

  modelEvents: {
    change: 'render',
  },

  ui: {
    serialChecks: '[data-action=toggle-serial]',
  },

  events: {
    'click [data-action=add]': 'addClicked',
    'click [data-action=subtract]': 'subtractClicked',
    'click [data-action=toggle]': 'toggleClicked',
    'click @ui.serialChecks': 'toggleSerialClicked',
  },

  initialize({ showSku }) {
    this.showSku = showSku;
  },

  addClicked() {
    const quantity = this.model.get('to_be_shipped_quantity');
    this.setToBeShipped(parseInt(quantity, 10) + 1);
  },

  subtractClicked() {
    const quantity = this.model.get('to_be_shipped_quantity');
    this.setToBeShipped(parseInt(quantity, 10) - 1);
  },

  setToBeShipped(quantity) {
    // Update the shipped quantity
    const maxQuantity = this.model.get('unfulfilled_quantity');
    if (quantity <= parseInt(maxQuantity, 10) && quantity >= 0) {
      this.model.set('to_be_shipped_quantity', quantity);
      this.ensureSerialQuantity(quantity);
    }
  },

  onRender() {
    // Setup the view
    const view = new IntegerInput({
      maxNumber: this.model.get('unfulfilled_quantity'),
      attributeName: 'to_be_shipped_quantity',
      model: this.model,
    });
    // Render view in region
    const region = this.getRegion('input');
    region.show(view);
  },

  ensureSerialQuantity(quantity) {
    const serials = this.getCheckedSerials();
    if (serials.length > quantity) {
      let leftChecked = quantity;
      this.ui.serialChecks
        .filter(':checked')
        .each((i, serialEl) => {
          leftChecked -= 1;
          if (leftChecked < 0) {
            $(serialEl).click();
          }
        });
    }
  },

  toggleClicked(ev) {
    const el = ev.currentTarget;
    const quantity = el.checked ? this.model.get('unfulfilled_quantity') : 0;
    this.model.set('to_be_shipped_quantity', quantity);
    this.ensureSerialQuantity(quantity);
  },

  getCheckedSerials() {
    const to_be_shipped_serial_nos = [];
    this.ui.serialChecks.filter(':checked')
      .each((i, el) => {
        let serial = $(el).data('serial');
        if (_.isNumber(serial)) {
          // if there are only numbers data it will convert int which we don't want
          serial = serial.toString();
        }
        to_be_shipped_serial_nos.push(serial);
      });
    return to_be_shipped_serial_nos;
  },

  toggleSerialClicked(ev) {
    const to_be_shipped_serial_nos = this.getCheckedSerials();
    let to_be_shipped_quantity = this.model.get('to_be_shipped_quantity');
    if (to_be_shipped_quantity < to_be_shipped_serial_nos.length) {
      to_be_shipped_quantity = to_be_shipped_serial_nos.length;
    }
    if (to_be_shipped_quantity > this.model.get('unfulfilled_quantity')) {
      to_be_shipped_quantity = this.model.get('unfulfilled_quantity');
    }
    this.model.unset('to_be_shipped_serial_nos', { silent: true });
    this.model.set({ to_be_shipped_serial_nos, to_be_shipped_quantity });
  },

  serializeData() {
    const serial_nos_list = [];
    const checked_serials = this.model.get('to_be_shipped_serial_nos') || [];
    const shipped_serials_serials = this.model.get('shipped_serial_nos') || [];
    (this.model.get('serial_nos') || []).forEach(
      (serial_no) => {
        if (!shipped_serials_serials.includes(serial_no)) {
          serial_nos_list.push({
            serial_no,
            checked: checked_serials.includes(serial_no),
          });
        }
      },
    );
    return {
      ...this.model.toJSON(),
      showSku: this.showSku,
      serial_nos_list,
    };
  },

}));
