define([
  'jquery',
  'underscore',
  'backbone',
  'modules/common/views/swappableWithLoading',

  './list',

  'modules/shop.cash-register-retail/components/categoryCache',
], (
  $, _, Backbone, SwappableView,
  CollectionView,
  CategoryCache,
) => SwappableView.extend({

  initialize(options) {
    SwappableView.prototype.initialize.call(this, options);

    this.setMainView(CollectionView, options);
  },

  loadDataForView(name, childOptions, dataStorage) {
    const def = new $.Deferred();

    CategoryCache.load()
      .then(() => {
        dataStorage.collection = CategoryCache.getCategoryCollection();
        def.resolve();
      }, def.reject);

    return def;
  },

}));
