define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop.cash-register-retail/templates/repairs/create.hbs',

  'modules/shop.cash-register-retail/views/inputs/customerSearchInput',
  'modules/shop.cash-register-retail/views/inputs/employeeSearchInput',
  'modules/shop.cash-register-retail/views/inputs/productSearchInputSelect2',

  'modules/common/components/historyBreadcrumb',
  'modules/upx/behaviors/validation',
  'modules/upx/behaviors/modelBinder',
  'modules/common/components/locale',
  'modules/admin/behaviors/loader',
  'modules/shop.cash-register-retail/components/datepickk',
  'modules/common/components/moment',
  'toastr',

  'upx.modules/ShopModule/models/Repair',

  'modules/admin/components/select2',
], (
  $, _, Backbone, Template,
  CustomerSearchView, EmployeeSearchView, ProductSearchView,
  HistoryBreadcrumb, Validation, ModelBinder, Locale, Loader, Datepickk, Moment, Toastr,
  RepairModel,
) => Backbone.Marionette.LayoutView.extend({

  template: Template,

  ui: {
    productSerial: '#product-serial',
    dayPicker: '#day-picker',
    selectedDate: '#selected-date',
  },

  behaviors: {
    Loader: {
      behaviorClass: Loader,
    },
    ModelBinder: {
      behaviorClass: ModelBinder,
      method: 'newRepair',
    },
    Validation: {
      behaviorClass: Validation,
      method: 'newRepair',
      rules: {
        agreed_delivery_date: {
          required: true,
        },
        repaired_item_name: {
          required: true,
        },
        description: {
          required: true,
        },
        relation_data_id: {
          required: true,
        },
      },
    },
  },

  events: {
    'click [data-action="create-repair"]': 'createRepair',
    'click [data-action="back"]': 'goBack',
    'click [data-action="create-customer"]': 'createCustomer',
    'keyup @ui.productSerial': 'handleEnterOnSerial',
    'click @ui.dayPicker': 'showDayPicker',
  },

  regions: {
    customerSearch: '[data-region="customer-search-region"]',
    employeeSearch: '[data-region="employee-search-region"]',
    productSearch: '[data-region="product-search-region"]',
  },

  initialize() {
    this.model = new RepairModel({
      is_anonymous: false,
    });

    this.customerSearchView = new CustomerSearchView();
    this.customerSearchView.on('customer:selected', (model) => {
      this.model.set('relation_data_id', model.get('id'));
    });

    this.employeeSearchView = new EmployeeSearchView({
      allowClear: true,
    });
    this.employeeSearchView.on('employee:selected', (model) => {
      this.model.set('handling_employee_id', model.get('group_member.relation_data_id'));
    });
    this.employeeSearchView.on('employee:removed', () => {
      this.model.set('handling_employee_id', null);
    });

    this.productSearchView = new ProductSearchView({
      resetAfterSelection: false,
      allowCreationFromChoice: true,
    });
    this.productSearchView.on('select', this.handleSearchSelect, this);
  },

  showDayPicker() {
    const datepicker = new Datepickk();

    datepicker.lang = Locale.getLocale();
    datepicker.button = Locale.translate('ok');
    datepicker.today = true;
    datepicker.maxSelections = 1;
    datepicker.minDate = new Moment();
    datepicker.onConfirm = () => {
      const [date] = datepicker.selectedDates;

      this.setAgreedDeliveryDate(date);
    };

    datepicker.show();
  },

  setAgreedDeliveryDate(date) {
    const moment = new Moment(date);
    this.model.set('agreed_delivery_date', moment.format('YYYY-MM-DD'));

    this.ui.selectedDate.text(moment.format('LL'));
  },

  createCustomer() {
    HistoryBreadcrumb.goto('#customers/create');
  },

  goBack() {
    HistoryBreadcrumb.goBack();
  },

  handleSearchSelect(id, model) {
    if (model.get('fromChoice')) {
      this.model.set('repaired_item_name', model.get('product_name'));
    } else {
      this.model.set({
        repaired_item_name: model.get('flat_product.title'),
        repaired_item_id: id,
      });
    }
  },

  handleEnterOnSerial(ev) {
    if (ev.keyCode === 13 && this.ui.productSerial.val().trim()) {
      $('#description').focus();
    }
  },

  createRepair() {
    if (this.model.isValid(true)) {
      const def = this.loader.startLoader();
      $.when(this.model.save()).then((id) => {
        def.resolve();
        Toastr.success(Locale.translate('new_repair_has_been_created'), Locale.translate('repairs'));

        HistoryBreadcrumb.goto(`#repairs/${id}`);
      }, (data) => {
        def.reject();
        Toastr.error(Locale.translate(data.error), Locale.translate('repairs'));
      });
    }
  },

  onRender() {
    const customerSearchRegion = this.getRegion('customerSearch');
    customerSearchRegion.show(this.customerSearchView);

    const employeeSearchRegion = this.getRegion('employeeSearch');
    employeeSearchRegion.show(this.employeeSearchView);

    const productSearchRegion = this.getRegion('productSearch');
    productSearchRegion.show(this.productSearchView);

    this.setAgreedDeliveryDate((new Moment()).add('days', 3));
  },
}));
