define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop.cash-register-retail/templates/tables/overview/layout.hbs',

  './search',
  './table/collection',
  './tableArea/collection',

  'modules/shop.cash-register-retail/collections/loadable/tableAreas',
], (
  $, _, Backbone, Template,
  SearchView, TableCollectionView, TableAreaCollectionView,
  TableAreas,
) => {
  const TABLE_MODE_ADD = 'table-mode-add';
  const TABLE_MODE_INFORMATION = 'table-mode-information';
  const View = Backbone.Marionette.LayoutView.extend({

    className: 'table-overview',

    template: Template,

    regions: {
      search: '[data-region=search]',
      tables: '[data-region=tables]',
      tableAreas: '[data-region=table-areas]',
    },

    initialize({ tableMode, printProductionReceipts }) {
      this.model = new Backbone.Model({
        searchQuery: '',
        tableAreaId: '',
        tableMode: tableMode || TABLE_MODE_INFORMATION,
        printProductionReceipts: printProductionReceipts || false,
      });

      // Set initial tableArea
      const tableArea = TableAreas.first();
      if (tableArea) this.model.set('tableAreaId', tableArea.get('id'));
    },

    onRender() {
      this.renderSearch();
      this.renderTables();
      this.renderTableAreas();
    },

    renderSearch() {
      const region = this.getRegion('search');
      const view = new SearchView({
        model: this.model,
      });
      region.show(view);
    },

    renderTables() {
      const region = this.getRegion('tables');
      const view = new TableCollectionView({
        model: this.model,
        tableModes: {
          MODE_ADD: TABLE_MODE_ADD,
          MODE_INFORMATION: TABLE_MODE_INFORMATION,
        },
      });
      region.show(view);
    },

    renderTableAreas() {
      const region = this.getRegion('tableAreas');
      const view = new TableAreaCollectionView({
        model: this.model,
      });
      region.show(view);
    },

    onShow() {
      this.model.on('item:added', () => this.triggerMethod('item:added'));
      this.model.on('loader:start', (def) => this.triggerMethod('loader:start', def));
    },

    onDestroy() {
      this.model.off('item:added', () => this.triggerMethod('item:added'));
      this.model.off('loader:start', (def) => this.triggerMethod('loader:start', def));
    },

  });

  View.TABLE_MODE_ADD = TABLE_MODE_ADD;
  View.TABLE_MODE_INFORMATION = TABLE_MODE_INFORMATION;

  return View;
});
