define([
  'jquery',
  'underscore',
  'backbone',
  'modules/mobile/views/popup',
  'modules/shop.cash-register-retail/templates/shippingModal/partialPopup',

  './overview/collection',

  'modules/shop.cash-register-retail/collections/orderItem',
  'modules/shop.cash-register-retail/collections/currentOrderItem',

  'upx.modules/ShopModule/models/OrderShipment',
  'upx.modules/ShippingModule/collections/ShipmentStatusType',
  'upx.modules/ShippingModule/models/Shipment',

  'modules/shop.cash-register-retail/components/toaster',
  'modules/common/components/locale',
], (
  $, _, Backbone, PopupView, Template,
  OverviewView,
  OrderItemCollection, CurrentOrderItemCollection,
) => PopupView.extend({

  template: Template,

  className: 'dialog dialog--handout',

  ui: {
    closeButton: '[data-ui=close]',
    backButton: '[data-ui=back]',
    saveButton: '[data-ui=save]',
    content: '[data-ui=content]',
    method: '[data-ui=method]',
  },

  regions: {
    content: '@ui.content',
  },

  events: {
    // Close
    'click @ui.closeButton': 'closeClicked',
    'touchend @ui.closeButton': 'closeClicked',
    // Save
    'click @ui.backButton': 'closeClicked',
    // Save
    'click @ui.saveButton': 'saveClicked',
    // method
    'change @ui.method': 'methodChanged',
  },

  initialize({ orderModel, shippingMethodCollection, orderItemCollection = null }) {
    this.shippingMethodCollection = shippingMethodCollection;
    this.orderModel = orderModel;
    this.orderItemCollection = orderItemCollection || CurrentOrderItemCollection;
    this.collection = this.getCollection();

    this.shippingMethodId = null;
    const shippingMethodModel = this.shippingMethodCollection.first();
    if (this.orderModel.has('shipping_method_id')) {
      this.shippingMethodId = this.orderModel.get('shipping_method_id');
    } else if (shippingMethodModel) {
      this.shippingMethodId = shippingMethodModel.get('id');
    }
  },

  open() {
    this.logOpenAction(
      'modules/shop.cash-register-retail/views/shippingModal/partialPopup',
    );

    const def = this.renderInFloatingRegion();

    this.renderContent();

    this.openPopup();
    return def;
  },

  getCollection() {
    const collection = new OrderItemCollection();

    this.orderItemCollection.each((model) => {
      if (model.get('unfulfilled_quantity') > 0) {
        // only positive can set to partial
        const orderModel = collection.addProductByOrderItemModel({
          orderItemModel: model,
          saveModel: false,
        });
        let quantity = orderModel.get('unfulfilled_quantity');
        if (model.has('to_be_shipped_quantity')) {
          quantity = model.get('to_be_shipped_quantity');
        }
        let serial_nos = [];
        if (model.has('to_be_shipped_serial_nos')) {
          serial_nos = model.get('to_be_shipped_serial_nos');
        } else if (model.has('serial_nos')) {
          serial_nos = model.get('serial_nos');
        }
        orderModel.unset('to_be_shipped_serial_nos', { silent: true });
        orderModel.set({
          to_be_shipped_quantity: quantity,
          to_be_shipped_serial_nos: serial_nos,
        });
      }
    });

    return collection;
  },

  renderContent() {
    const { collection } = this;
    // Setup the view
    const view = new OverviewView({
      mode: 'edit',
      showSku: false,
      collection,
    });
    // Render view in region
    const region = this.getRegion('content');
    region.show(view);
  },

  methodChanged(ev) {
    const el = ev.currentTarget;
    this.shippingMethodId = parseInt(el.value, 10);
  },

  saveClicked() {
    this.orderModel.set('shipping_method_id', this.shippingMethodId);
    this.collection.each((model) => {
      // Extract data
      const {
        // eslint-disable-next-line camelcase
        order_item_id, unfulfilled_quantity, to_be_shipped_quantity,
        to_be_shipped_serial_nos,
      } = model.toJSON();
      // Apply data
      const currentOrderItem = this.orderItemCollection.get(order_item_id);
      currentOrderItem.unset('to_be_shipped_serial_nos', { silent: true });
      currentOrderItem.set({
        unfulfilled_quantity,
        to_be_shipped_quantity,
        to_be_shipped_serial_nos,
      });
    });

    this.close();
  },

  serializeData() {
    const collection = this.shippingMethodCollection;
    const model = this.shippingMethodCollection.first();
    return {
      shippingMethodId: this.shippingMethodId,
      methods: collection.toJSON(),
      methodName: model ? model.get('name') : null,
      multipleMethods: collection.length > 1,
    };
  },

}));
