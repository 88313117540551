define([
  'underscore',
  'modules/shop.cash-register-retail/views/products/list/row',
  './cellReserve',
  './cellEmpty',
], (_, View, CellReserveView, CellEmptyView) => View.extend({
  getChildView(child) {
    if (child.get('id') === 'reserve') {
      return CellReserveView;
    } if (this.model.get('is_reserved') && child.get('id') === 'delete') {
      return CellEmptyView;
    }

    return View.prototype.getChildView.call(this, child);
  },

  modelEvents() {
    return _.extend({}, View.prototype.modelEvents, {
      'change:is_reserved': 'isReservedChanged',
    });
  },

  isReservedChanged() {
    this.render();
  },

  childViewOptions(model) {
    return _.extend({}, View.prototype.childViewOptions.call(this, model), {
      isEditable: !this.model.get('is_reserved'),
    });
  },
}));
