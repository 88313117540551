define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop.cash-register-retail/templates/stickerPrint/listItem.hbs',

  'modules/upx/components/upx',
  'modules/admin/behaviors/loader',
  'modules/shop.cash-register-retail/views/popups/categoryPopup',
  'modules/shop.cash-register-retail/components/printing',
], (
  $, _, Backbone, Template,
  Upx, Loader, CategoryPopupView, PrintingComponent,
) => Backbone.Marionette.LayoutView.extend({

  template: Template,

  className: 'designer-box rounded',

  regions: {
    popup: '[data-region="popup"]',
  },

  behaviors: {
    Loader: {
      behaviorClass: Loader,
    },
  },

  events: {
    click: 'handleClicked',
  },

  handleClicked() {
    const def = this.loader.startLoader();
    const view = new CategoryPopupView();

    view.on('child:clicked', (model) => {
      PrintingComponent.printPriceSticker(model);
    });

    view.open(def, this.model.get('id'), this.model.get('title'));
  },

  serializeData() {
    const data = this.model.toJSON();

    data.image = 'images/no-product-image.png';
    if (this.model.has('image_url')) {
      data.image = Upx.getResourceUrl(this.model.get('image_url'));
    }

    return data;
  },

}));
