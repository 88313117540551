define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop.cash-register-retail/templates/tabs/listPopup/empty.hbs',
], (
  $, _, Backbone, Template,
) => Backbone.Marionette.LayoutView.extend({

  template: Template,

  className: 'no-tabs',

}));
