define([
  'jquery',
  'underscore',
  'backbone',
  './item',
  'modules/shop.cash-register-retail/templates/tables/overview/table/itemAdd.hbs',

  'modules/common/components/promisify',
  'modules/common/components/locale',
  'modules/shop.cash-register-retail/components/toaster',
  'modules/shop.cash-register-retail/components/productionGroupPrint',
  'modules/admin/behaviors/loader',

  'modules/shop.cash-register-retail/collections/currentOrderItem',
], (
  $, _, Backbone, ItemView, Template,
  Promisify, Locale, Toaster, ProductionGroupPrint, Loader,
  CurrentOrderItem,
) => ItemView.extend({

  template: Template,

  behaviors: {
    Loader: {
		        behaviorClass: Loader,
      name: 'add-item',
		    },
  },

  events: {
    'click [data-action=add-to-table]': 'addClicked',
  },

  initialize({ overviewModel }) {
    this.overviewModel = overviewModel;
  },

  addClicked: _.debounce(async function () {
    // Start loader
    const def = new $.Deferred();
    this.overviewModel.trigger('loader:start', def);

    try {
      // Ensure table order exists
      await this.model.ensureTableOrder();

      // Ensure the table is up to date
      await this.model.fetchPromise();

      // Create order part
      const tableOrderPartObject = await this.model.ensureTableOrderPart();

      // Print production receipts if needed
      const printProductionReceipts = this.overviewModel.get('printProductionReceipts');
      if (printProductionReceipts) {
        // Increment print count
        await ProductionGroupPrint.incrementTableOrderPartPrintCount(tableOrderPartObject.id);
        await ProductionGroupPrint.printFromTableOrderPartProduction(this.model.get('id'), tableOrderPartObject);
      } else {
        await ProductionGroupPrint.printFromTableOrderPartAnnouncement(this.model.get('id'), tableOrderPartObject);
      }

      // Clear order items from order
      CurrentOrderItem.clear();

      // Show success
      const table = this.model.get('name');
      const table_area = this.model.get('table_area.name');
      const successText = Locale.translate('items_added_to_{table}_{table_area}', { table, table_area });
      Toaster.success(successText);

      // Done
      def.resolve();

      // Trigger update
      this.overviewModel.trigger('item:added');
    } catch (err) {
      console.error(err);
      const message = Promisify.extractTryCatchErrorMessage(err);
      Toaster.error(message);
      def.reject();
    }
  }, 100),

}));
